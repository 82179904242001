export function genUiUsersState () {
  return {
    isLoading: true,
    isNextLoading: false,
    list: [],
    fetchNext: null, // function
  }
}

export default genUiUsersState
