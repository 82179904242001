import { uiUsersMutations } from './types'
import Vue from 'vue'

export default {
  [uiUsersMutations.SET_IS_LOADING] (state, value) {
    state.isLoading = value
  },

  [uiUsersMutations.SET_IS_NEXT_LOADING] (state, value) {
    state.isNextLoading = value
  },

  [uiUsersMutations.SET_LIST] (state, value) {
    state.list = value
  },

  [uiUsersMutations.PUSH_LIST] (state, value) {
    state.list = state.list.concat(value)
  },

  [uiUsersMutations.SET_FETCH_NEXT] (state, value) {
    state.fetchNext = value
  },

  [uiUsersMutations.REMOVE_ITEM] (state, id) {
    state.list = state.list.filter(el => el.id !== id)
  },

  [uiUsersMutations.UPDATE_ITEM] (state, item) {
    const index = state.list.findIndex(el => el.id === item.id)
    Vue.set(state.list, index, item)
  },
}
