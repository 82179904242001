import { uiUsersActions, uiUsersMutations, uiUsersGetters } from './types'
import { sdk } from 'Services/shelfNetworkSdk'
import { USERS_PAGE_LIMIT } from '../constants'
import omitBy from 'lodash/omitBy'
import { LoneSdkCall } from 'Utils/LoneSdkCall'
import { userGetters } from 'Store/entities/User/types'
import { rootGet } from 'Store/helpers/rootHelpers'

const loneSdkCall = new LoneSdkCall()

export default {
  async [uiUsersActions.LOAD_LIST] ({ commit }, payload = {}) {
    commit(uiUsersMutations.SET_IS_LOADING, true)

    payload = normalizePayload(payload)

    const { data, fetchNext } = await loneSdkCall.takeLatest(
      sdk.horizon.account.getAccounts(payload)
    )

    commit(uiUsersMutations.SET_LIST, data)
    commit(uiUsersMutations.SET_FETCH_NEXT, fetchNext)
    commit(uiUsersMutations.SET_IS_LOADING, false)
  },

  async [uiUsersActions.LOAD_ITEM] ({ commit }, payload = {}) {
    // TODO: broken, should be similar to ui/leads/LOAD_LEAD
    const response = await sdk.horizon.account.get(payload.userId)
    commit(uiUsersMutations.PUSH_LIST, response)
  },

  async [uiUsersActions.LOAD_MORE] ({ commit, getters }) {
    const fetchNext = getters[uiUsersGetters.FETCH_NEXT]
    if (!fetchNext) return

    commit(uiUsersMutations.SET_IS_NEXT_LOADING, true)

    const { data, fetchNext: newFetchNext } = await loneSdkCall.takeLatest(
      fetchNext()
    )
    commit(uiUsersMutations.PUSH_LIST, data)
    commit(uiUsersMutations.SET_FETCH_NEXT, newFetchNext)

    commit(uiUsersMutations.SET_IS_NEXT_LOADING, false)
  },

  // PROXIES

  async [uiUsersActions.REMOVE_ITEM] ({ commit }, id) {
    commit(uiUsersMutations.REMOVE_ITEM, id)
  },

  async [uiUsersActions.UPDATE_ITEM] ({ commit }, item) {
    commit(uiUsersMutations.UPDATE_ITEM, item)
  },

  async [uiUsersActions.PUSH_ITEM] ({ commit }, item) {
    commit(uiUsersMutations.PUSH_LIST, item)
  }
}

function normalizePayload (payload) {
  const result = Object.assign({}, payload)

  result.platform = payload.platform ||
    rootGet(`entities/user/${userGetters.PLATFORM_ID}`)

  result.page = Object.assign({}, payload.page)
  result.page.limit = USERS_PAGE_LIMIT

  return omitBy(result, val => !val)
}
