import { uiUsersGetters } from './types'
import { UserEntry } from 'Models/UserEntry'

export default {
  [uiUsersGetters.IS_LOADING]: state => state.isLoading,

  [uiUsersGetters.IS_NEXT_LOADING]: state => state.isNextLoading,

  [uiUsersGetters.LIST]: state => state.list
    .map(item => new UserEntry(item)),

  [uiUsersGetters.LIST_RAW]: state => state.list,

  [uiUsersGetters.FETCH_NEXT]: state => state.fetchNext,
}
